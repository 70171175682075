<template>
  <b-container fluid>
    <b-row>
      <b-col lg="6" class="py-4" style="background: white;">
        <h5 class="px-3">Recently updated policies</h5>
        <b-pagination
          inline
          v-model="currentPageInitiatives"
          :total-rows="initiativesAll.length"
          :per-page="perPageInitiatives"
          class="mt-3"
        ></b-pagination>
          <div class="px-3 py-3" v-for="(initiative, index) in initiativesShow" :key="'initiative-' + index" :id="'initiative'+initiative.id">
            <div>
              <router-link :to="{ name: 'ImpactPoliciesInitiative', params: { id: initiative.id }}">
                <div><small>{{moment(initiative.updated_at).format('YYYY-MM-DD')}}</small></div>
                <div>{{initiative.country}} - {{initiative.name}}</div>
              </router-link>
            </div>
          </div>
      </b-col>
      <b-col lg="6" class="py-4">
        <h5 class="px-3">Industry news</h5>
        <div v-if="loading">
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 4em; margin-top: 100px;"></i></h1>
        </div>
        <div v-if="!loading">
          <b-pagination
            inline
            v-model="currentPageNews"
            :total-rows="news.length"
            :per-page="perPageNews"
            class="mt-3"
          ></b-pagination>
          <div class="px-3 py-3" v-for="(item, index) in newsShow" :key="'news-' + index">
            <div>
              <router-link :to="{ name: 'NewsItem', params: { id: item.id }}">
                <div><small>{{item.date}} - {{ item.countryName }} - {{ item.publisher }}</small></div>
                <div>{{item.name}}</div>
              </router-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
export default {
  name: 'PoliciesList',
  components: {
  },
  computed: {
    initiativesAll: function () {
      return _.orderBy(this.$store.state.initiativesPolicies, ['updated_at'], ['desc'])
    },
    initiativesShow: function () {
      const start = (this.currentPageInitiatives - 1) * this.perPageInitiatives
      const end = this.currentPageInitiatives * this.perPageInitiatives
      return this.initiativesAll.slice(start, end)
    },
    newsShow: function () {
      const start = (this.currentPageNews - 1) * this.perPageNews
      const end = this.currentPageNews * this.perPageNews
      return this.news.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Climate Policy and Regulatory Dashboard - News"
    this.$stat.log({ page: 'impactpolicies news', action: 'open impactpolicies news' })
    this.load()
  },
  data () {
    const data = {
      currentPageInitiatives: 1,
      currentPageNews: 1,
      loading: true,
      moment: moment,
      news: [],
      perPageInitiatives: 6,
      perPageNews: 6
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.news = await this.$Amplify.API.get('cosmos', '/news/anypolicy/60')
      this.loading = false
    }
  }
}
</script>

<style>
</style>
